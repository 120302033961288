// Libs
import React, { useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import ReactTooltip from "react-tooltip";

// Components
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SingleImageContainer from "../ui/SingleImageContainer";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";

// Utilities
import ReactStars from "react-stars";
import ChangeTimeModal from "./ChangeTimeModal";
import { workHours } from "../../utilities/get-work-hours-total";
import { addCommas } from "../../utilities/add_commas";
import { remove_commas } from "../../utilities/remove_commas";
import req from "../../utilities/request-utility";

// Config
import userTypes from "../../config/userTypes";
import shiftTypes from "../../config/shiftTypes";

// Styles
import colors from "../../styles/colors";
import InfoIcon from "mdi-react/InfoCircleIcon";

// Actions
import { takeOverEmployee } from "../../actions/authActions";
import { addToast, hideModal, showModal } from "../../actions/uiActions";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const BookingInvoiceShift = (props) => {
  const ref = useRef();

  // State
  const [showOptions, setShowOptions] = useState(false);

  // Props
  const s = props.shift;
  const booking = props.booking;

  useOnClickOutside(ref, () => {
    setShowOptions(false);
  });

  const notMetForBookingHandler = async (shiftId, metStatus) => {
    try {
      await req().put(`admin/shifts/${shiftId}/met`, {
        metStatus,
      });
      props.getBookings();
    } catch (error) { }
  };
  const updateSelfPaidBreakStatus = async (bookingId, status) => {
    try {
      await req().put(`admin/bookings/${bookingId}/update-self-paid-break`, {
        status,
      });
      props.getBookings();
    } catch (error) { }
  };

  const {
    corporation_checkin_time,
    corporation_checkout_time,
    employee_checkin_time,
    employee_checkout_time,
    checkout_rejected_count_employee,
    checkout_rejected_count_corporation,
    last_checkout_by,
    admin_employee_time,
    admin_check_time
  } = s;

  const employeeCheckedOut = employee_checkin_time && employee_checkout_time;
  const corporationCheckedOut =
    corporation_checkin_time && corporation_checkout_time;

  const checkoutRejected =
    employeeCheckedOut &&
    corporationCheckedOut &&
    (corporation_checkin_time != employee_checkin_time ||
      corporation_checkout_time != employee_checkout_time);
  const checkoutApproved =
    employeeCheckedOut &&
    corporationCheckedOut &&
    corporation_checkin_time == employee_checkin_time &&
    corporation_checkout_time == employee_checkout_time;

  const checkoutRejectedByCompany =
    checkoutRejected && last_checkout_by == "corporation";
  const checkoutApprovedByCompany =
    checkoutApproved && last_checkout_by == "corporation";
  const checkoutApprovedByEmployee =
    checkoutApproved && last_checkout_by == "employee";

  let companyColor =
    checkoutRejected &&
      !(checkoutRejectedByCompany && !checkout_rejected_count_employee)
      ? "#a83b3b"
      : checkoutApprovedByEmployee
        ? "#417505"
        : "";
  let employeeColor =
    checkoutRejectedByCompany ||
      (checkoutRejected && checkout_rejected_count_corporation > 0)
      ? "#a83b3b"
      : checkoutApprovedByCompany
        ? "#417505"
        : "";

  const companyCheckoutTime =
    s.corporation_checkin_time && s.corporation_checkout_time
      ? s.corporation_checkin_time + ` - ` + s.corporation_checkout_time
      : `--:-- --:--`;
  const employeeCheckoutTime = s.employee_checkin_time && s.employee_checkout_time
    ? s.employee_checkin_time + ` - ` + s.employee_checkout_time
    : `--:-- --:--`;

  if (s.employee_checkin_time && s.employee_checkout_time && checkoutApprovedByCompany && !admin_employee_time) {
    s.admin_employee_checkin_time = s.employee_checkin_time;
    s.admin_employee_checkout_time = s.employee_checkout_time;
  }

  if (s.employee_checkin_time && s.employee_checkout_time && checkoutApprovedByCompany && !admin_check_time) {
    s.admin_checkin_time = s.employee_checkin_time;
    s.admin_checkout_time = s.employee_checkout_time;
  }

  const adminEmployeeCheckoutTimes = {
    start_time: s.admin_employee_checkin_time,
    end_time: s.admin_employee_checkout_time,
  };
  // const adminEmployeeCheckoutTimes = checkoutApprovedByCompany ? {start_time: s.corporation_checkin_time, end_time : s.corporation_checkout_time } : checkoutApprovedByEmployee ? {start_time: s.employee_checkin_time, end_time : s.employee_checkout_time } : s.admin_employee_checkin_time && s.admin_employee_checkout_time ? {start_time: s.admin_employee_checkin_time, end_time : s.admin_employee_checkout_time } : {start_time: booking.start_time, end_time : booking.end_time }
  const adminCompanyCheckoutTimes = {
    start_time: s.admin_checkin_time,
    end_time: s.admin_checkout_time,
  };
  // const adminCompanyCheckoutTimes = checkoutApprovedByCompany ? {start_time: s.corporation_checkin_time, end_time : s.corporation_checkout_time } : checkoutApprovedByEmployee ? {start_time: s.employee_checkin_time, end_time : s.employee_checkout_time } : s.admin_checkin_time && s.admin_checkout_time ? {start_time: s.admin_checkin_time, end_time : s.admin_checkout_time } : {start_time: booking.start_time, end_time : booking.end_time };
  return (
    <tbody key={s.id}>
      <tr
        className={`table-body ${s.status === shiftTypes.sick ? "not-selected" : ""
          } ${s.not_met_for_booking ? "not-met-for-booking" : ""} ${s.status === "removed" ? "removed-from-booking" : ""
          }`}
      >
        <th style={{ width: "30px" }}>
          <div className="options-icon-container">
            <DotsVerticalIcon
              onClick={() => setShowOptions(true)}
              className="down-icon"
            />
            {showOptions && (
              <div ref={ref} className="options">
                <p
                  onClick={() => {
                    props.takeOverEmployee(s.employee.id);
                    setShowOptions(false);
                  }}
                >
                  Overtag
                </p>
                <p
                  onClick={async () => {
                    setShowOptions(false);
                    await notMetForBookingHandler(s.id, !s.not_met_for_booking);
                  }}
                >
                  {!s.not_met_for_booking ? "Ikke mødt" : "mødt"}
                </p>
                <p
                  onClick={async () => {
                    setShowOptions(false);
                    await updateSelfPaidBreakStatus(s.booking.id, !s.booking.self_paid_break);
                  }}
                >
                  {!s.booking.self_paid_break ? "Pause (-30)" : "Pause (+30)"}
                </p>
              </div>
            )}
          </div>
        </th>
        <th style={{ width: "70px" }}>
          {" "}
          <SingleImageContainer
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            imageStyle={{ height: "3rem", width: "3rem" }}
            cloudinaryString={"c_thumb,g_face,h_100,w_100/dpr_2.0,f_auto/"}
            baseURL={s.employee.baseURL_image}
            image={s.employee.profile_image}
          />
        </th>
        <td>
          <p>{`${s.employee.first_name} ${s.employee.last_name
            .substring(0, 1)
            .toUpperCase()}.`}</p>
          <p>{`ID: ${s.employee.id}`}</p>
          <p>{s.employee.phone}</p>
          <p className="label">{booking.jobRole?.job_title}</p>
        </td>
        <td>
          <ReactStars
            className={"rating"}
            value={s.employeeRating && s.employeeRating.rating}
            size={12}
            edit={false}
          />
          {s.employeeRating &&
            s.employeeRating.comment &&
            s.employeeRating.comment.trim() != "" ? (
            <>
              <ReactTooltip
                id={`corporation-comment-${s.id}`}
                place="top"
                type="dark"
                effect="solid"
              >
                {(s.employeeRating && s.employeeRating.comment) || ""}
              </ReactTooltip>

              <button
                data-tip
                data-for={`corporation-comment-${s.id}`}
                className={"comment"}
              >
                Virksomhed
              </button>
            </>
          ) : (
            <button className={"comment disabled"}>Virksomhed</button>
          )}
          <hr />
          <ReactStars
            className={"rating"}
            value={s.corporationRating && s.corporationRating.rating}
            size={12}
            edit={false}
          />
          {s.corporationRating &&
            s.corporationRating.comment &&
            s.corporationRating.comment.trim() != "" ? (
            <>
              <ReactTooltip
                id={`employee-comment-${s.id}`}
                place="top"
                type="dark"
                effect="solid"
              >
                {s.corporationRating && s.corporationRating.comment}
              </ReactTooltip>
              <button
                data-tip
                data-for={`employee-comment-${s.id}`}
                className={"comment"}
              >
                Personale
              </button>
            </>
          ) : (
            <button className={"comment disabled"}>Personale</button>
          )}
        </td>
        {/** Approval **/}
        <td style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: "26px",
              left: "19px",
            }}
            className="tb-container"
          >
            <input
              checked={s.admin_approve_invoice}
              onClick={() => props.updateInvoiceApproval(s)}
              style={{
                height: "1rem",
                width: "1rem",
              }}
              type={"radio"}
            />
          </div>
          <hr style={{ position: "absolute", top: "48px" }} />
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "19px",
            }}
            className="tb-container"
          >
            <input
              checked={s.admin_approve_checkout}
              onClick={() => props.updateShiftApproval(s)}
              style={{
                height: "1rem",
                width: "1rem",
              }}
              type={"radio"}
            />
          </div>
        </td>
        {/** Employee-, corporation- and admin overview **/}
        <td>
          <div className="tb-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: companyColor,
                  fontWeight: companyColor ? "bold" : "normal",
                }}
              >
                {`🏢  ${companyCheckoutTime}  `}{" "}
                <button
                  onClick={() =>
                    props.updateTimeStatus(userTypes.corporation, s, true)
                  }
                  className={`success ${s.corporation_time_status && "active"}`}
                  style={{ display: "inline-block" }}
                >
                  GODK.
                </button>{" "}
                {s.time_comment_by_corporation && (
                  <>
                    <ReactTooltip
                      id={`time-comment-corporation-${s.id}`}
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      {s.time_comment_by_corporation}
                    </ReactTooltip>

                    <InfoIcon
                      data-tip
                      data-for={`time-comment-corporation-${s.id}`}
                      size={16}
                      style={{ display: "inline-block" }}
                    />
                  </>
                )}
                <button
                  onClick={() =>
                    props.updateTimeStatus(userTypes.corporation, s, false)
                  }
                  className={`fail ${s.corporation_time_status === false && "active"
                    }`}
                >
                  AFVIS.
                </button>
              </p>
              <p>
                {
                  `${s.booking && s.booking.date
                    ? (() => {
                      let date = moment(s.booking.date).format("ddd DD/MM");
                      return (
                        date[0].toUpperCase() + date.substring(1, date.length)
                      );
                    })()
                    : ""
                  } `
                }
                {
                  s.admin_approve_invoice ?
                    adminCompanyCheckoutTimes.start_time && adminCompanyCheckoutTimes.end_time ? `${adminCompanyCheckoutTimes.start_time} - ${adminCompanyCheckoutTimes.end_time}` :
                      s.corporation_checkin_time && s.corporation_checkout_time ? `${companyCheckoutTime}` :
                        `${booking.start_time
                          ? booking.start_time
                          : "--:--"
                        } - ${booking.end_time
                          ? booking.end_time
                          : "--:--"
                        }  ` : "--:--"}{" "}
                <button
                  onClick={() =>
                    props.showModal({
                      title: "Virksomhed admin",
                      content: (
                        <ChangeTimeModal
                          userType={userTypes.corporation}
                          onAdminCheckout={props.onAdminCorporationCheckout}
                          shift={s}
                          booking={booking}
                        />
                      ),
                    })
                  }
                  style={{
                    color: colors.blue,
                    fontWeight: "bold",
                  }}
                >
                  RED
                </button>
                {s.admin_checkin_time &&
                  s.admin_checkout_time &&
                  (s.corporation_checkin_time !== s.admin_checkin_time ||
                    s.corporation_checkout_time !== s.admin_checkout_time) && (
                    <span className="not-match">obs</span>
                  )}
              </p>
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: employeeColor,
                  fontWeight: employeeColor ? "bold" : "normal",
                }}
              >
                {`👤  ${employeeCheckoutTime}  `}{" "}
                <button
                  onClick={() =>
                    props.updateTimeStatus(userTypes.employee, s, true)
                  }
                  className={`success ${s.employee_time_status && "active"}`}
                  style={{ display: "inline-block" }}
                >
                  GODK.
                </button>{" "}
                {s.time_comment_by_employee && (
                  <>
                    <ReactTooltip
                      id={`time-comment-employee-${s.id}`}
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      {s.time_comment_by_employee}
                    </ReactTooltip>

                    <InfoIcon
                      data-tip
                      data-for={`time-comment-employee-${s.id}`}
                      size={16}
                      style={{ display: "inline-block" }}
                    />
                  </>
                )}
                <button
                  onClick={() =>
                    props.updateTimeStatus(userTypes.employee, s, false)
                  }
                  className={`fail ${s.employee_time_status === false && "active"
                    }`}
                >
                  AFVIS
                </button>
              </p>
              <p>
                {
                  s.admin_approve_checkout ?
                    adminEmployeeCheckoutTimes.start_time && adminEmployeeCheckoutTimes.end_time ?
                      `(L) ${adminEmployeeCheckoutTimes.start_time} - ${adminEmployeeCheckoutTimes.end_time} ` : s.corporation_checkin_time && s.corporation_checkout_time ? `(L) ${companyCheckoutTime}` :
                        `(L) ${booking.start_time
                          ? booking.start_time
                          : "--:--"
                        } - ${booking.end_time
                          ? booking.end_time
                          : "--:--"
                        }` : "--:--"}
                {"  "}
                <button
                  onClick={() =>
                    props.showModal({
                      title: "Ansatte admin",
                      content: (
                        <ChangeTimeModal
                          userType={userTypes.employee}
                          onAdminCheckout={props.onAdminEmployeeCheckout}
                          shift={s}
                          booking={booking}
                        />
                      ),
                    })
                  }
                  style={{
                    color: colors.blue,
                    fontWeight: "bold",
                  }}
                >
                  RED
                </button>
                {s.admin_employee_checkin_time &&
                  s.admin_employee_checkout_time &&
                  (s.employee_checkin_time !== s.admin_employee_checkin_time ||
                    s.employee_checkout_time !==
                    s.admin_employee_checkout_time) && (
                    <span className="not-match">Obs</span>
                  )}
              </p>
            </div>
          </div>
        </td>
        {/** Hours in time format (Timer) **/}
        <td>
          <div className="tb-container">
            <p>
              {" "}
              {s.corporation_checkin_time && s.corporation_checkout_time
                ? `${workHours(
                  {
                    start_time: s.corporation_checkin_time,
                    end_time: s.corporation_checkout_time,
                  },
                  true
                )}`
                : "--:--"}
            </p>
            <p>
              {" "}
              {/* {s.admin_approve_invoice &&
              adminCompanyCheckoutTimes.start_time &&
              adminCompanyCheckoutTimes.end_time
                ? `${workHours(
                    {
                      start_time: adminCompanyCheckoutTimes.start_time,
                      end_time: adminCompanyCheckoutTimes.end_time,
                    },
                    true
                  )}`
                : "--:--"} */}
              {s.admin_approve_invoice ?
                adminCompanyCheckoutTimes.start_time &&
                  adminCompanyCheckoutTimes.end_time
                  ? `${workHours(
                    {
                      start_time: adminCompanyCheckoutTimes.start_time,
                      end_time: adminCompanyCheckoutTimes.end_time,
                    },
                    true
                  )}`
                  : s.corporation_checkin_time && s.corporation_checkout_time
                    ? `${workHours(
                      {
                        start_time: s.corporation_checkin_time,
                        end_time: s.corporation_checkout_time,
                      },
                      true
                    )}`
                    : "--:--" : "--:--"}
            </p>
          </div>
          <hr />
          <div className="tb-container">
            <p>
              {" "}
              {s.employee_checkin_time && s.employee_checkout_time
                ? `${workHours(
                  {
                    start_time: s.employee_checkin_time,
                    end_time: s.employee_checkout_time,
                  },
                  true
                )}`
                : "--:--"}
            </p>
            <p>
              {" "}
              {/* {s.admin_approve_checkout &&
              adminEmployeeCheckoutTimes.start_time &&
              adminEmployeeCheckoutTimes.end_time
                ? `${workHours(
                    {
                      start_time: adminEmployeeCheckoutTimes.start_time,
                      end_time: adminEmployeeCheckoutTimes.end_time,
                    },
                    true
                  )}`
                : "--:--"} */}
              {s.admin_approve_checkout ? adminEmployeeCheckoutTimes.start_time &&
                adminEmployeeCheckoutTimes.end_time
                ? `${workHours(
                  {
                    start_time: adminEmployeeCheckoutTimes.start_time,
                    end_time: adminEmployeeCheckoutTimes.end_time,
                  },
                  true
                )}`
                : s.corporation_checkin_time && s.corporation_checkout_time
                  ? `${workHours(
                    {
                      start_time: s.corporation_checkin_time,
                      end_time: s.corporation_checkout_time,
                    },
                    true
                  )}`
                  : "--:--" : "--:--"}
            </p>
          </div>
        </td>
        {/** Transport fee (Trans) **/}
        <td>
          <div className="tb-container">
            <p>
              {" "}
              {<>
                {(booking.transport_wage > 0 || booking.self_paid_break) ? (
                  <>
                    {booking.transport_wage > 0 ? addCommas(booking.transport_wage) : '-'} /
                    {booking.self_paid_break ? '-0,5' : '-'}
                  </>
                ) : (
                  "--:--"
                )}
              </>}
            </p>
            <p>
              {" "}
              {s.admin_approve_invoice && (booking.transport_wage > 0 || booking.self_paid_break) ? (
                  <>
                    {booking.transport_wage > 0 ? addCommas(booking.transport_wage) : '-'} /
                    {booking.self_paid_break ? '-0,5' : '-'}
                  </>
                ) : (
                  "--:--"
                )}
            </p>
          </div>
          <hr />
          <div className="tb-container">
            <p>
              {" "}
              {(booking.transport_wage > 0 || booking.self_paid_break) ? (
                  <>
                    {booking.transport_wage > 0 ? addCommas(booking.transport_wage) : '-'} /
                    {booking.self_paid_break ? '-0,5' : '-'}
                  </>
                ) : (
                  "--:--"
                )}
            </p>
            <p>
              {" "}
              {s.admin_approve_checkout && (booking.transport_wage > 0 || booking.self_paid_break) ? (
                  <>
                    {booking.transport_wage > 0 ? addCommas(booking.transport_wage) : '-'} /
                    {booking.self_paid_break ? '-0,5' : '-'}
                  </>
                ) : (
                  "--:--"
                )}
            </p>
          </div>
        </td>
        {/** Hours in total in decimal (I alt)  **/}
        <td>
          <div className="tb-container">
            <p>
              {" "}
              {s.corporation_checkin_time && s.corporation_checkout_time
                ? `${parseFloat(
                  workHours({
                    start_time: s.corporation_checkin_time,
                    end_time: s.corporation_checkout_time,
                  })
                ) + parseFloat(booking.transport_wage) - (booking.self_paid_break ? .5 : 0)
                }`
                : "-.-"}
            </p>
            <p>
              {" "}
              {/* {s.admin_approve_invoice &&
              adminCompanyCheckoutTimes.start_time &&
              adminCompanyCheckoutTimes.end_time
                ? `${
                    parseFloat(
                      workHours({
                        start_time: adminCompanyCheckoutTimes.start_time,
                        end_time: adminCompanyCheckoutTimes.end_time,
                      })
                    ) + parseFloat(booking.transport_wage)
                  }`
                : "-.-"} */}
              {s.admin_approve_invoice ? adminCompanyCheckoutTimes.start_time &&
                adminCompanyCheckoutTimes.end_time
                ? `${parseFloat(
                  workHours({
                    start_time: adminCompanyCheckoutTimes.start_time,
                    end_time: adminCompanyCheckoutTimes.end_time,
                  })
                ) + parseFloat(booking.transport_wage) - (booking.self_paid_break ? .5 : 0)
                }`
                : s.corporation_checkin_time && s.corporation_checkout_time
                  ? `${parseFloat(
                    workHours({
                      start_time: s.corporation_checkin_time,
                      end_time: s.corporation_checkout_time,
                    })
                  ) + parseFloat(booking.transport_wage) - (booking.self_paid_break ? .5 : 0)
                  }`
                  : "-.-" : "-.-"}
            </p>
          </div>
          <hr />
          <div className="tb-container">
            <p>
              {" "}
              {s.employee_checkin_time && s.employee_checkout_time
                ? `${parseFloat(
                  workHours({
                    start_time: s.employee_checkin_time,
                    end_time: s.employee_checkout_time,
                  })
                ) + parseFloat(booking.transport_wage) - (booking.self_paid_break ? .5 : 0)
                }`
                : "-.-"}
            </p>
            <p>
              {" "}
              {/* {adminEmployeeCheckoutTimes.start_time &&
              adminEmployeeCheckoutTimes.end_time
                ? `${
                    parseFloat(
                      workHours({
                        start_time: adminEmployeeCheckoutTimes.start_time,
                        end_time: adminEmployeeCheckoutTimes.end_time,
                      })
                    ) + parseFloat(booking.transport_wage)
                  }`
                : "-.-"} */}
              {s.admin_approve_checkout ? adminEmployeeCheckoutTimes.start_time &&
                adminEmployeeCheckoutTimes.end_time
                ? `${parseFloat(
                  workHours({
                    start_time: adminEmployeeCheckoutTimes.start_time,
                    end_time: adminEmployeeCheckoutTimes.end_time,
                  })
                ) + parseFloat(booking.transport_wage) - (booking.self_paid_break ? .5 : 0)
                }`
                : s.corporation_checkin_time && s.corporation_checkout_time
                  ? `${parseFloat(
                    workHours({
                      start_time: s.corporation_checkin_time,
                      end_time: s.corporation_checkout_time,
                    })
                  ) + parseFloat(booking.transport_wage) - (booking.self_paid_break ? .5 : 0)
                  }`
                  : "-.-" : "-.-"}
            </p>
          </div>
        </td>
        {/** Hourly wage (Timeløn)  **/}
        <td>
          <div className="tb-container">
            <p>-.-</p>
            <p>
              {/* {s.admin_approve_invoice &&
              adminCompanyCheckoutTimes.start_time &&
              adminCompanyCheckoutTimes.end_time
                ? `${addCommas(booking.hourly_wage)}/${addCommas(
                    remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: adminCompanyCheckoutTimes.start_time,
                          end_time: adminCompanyCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage))
                  )}`
                : `-.-/-.-`} */}
              {s.admin_approve_invoice ?
                adminCompanyCheckoutTimes.start_time &&
                  adminCompanyCheckoutTimes.end_time
                  ? `${addCommas(booking.hourly_wage)}/${addCommas(
                    remove_commas(booking.hourly_wage) *
                    (parseFloat(
                      workHours({
                        start_time: adminCompanyCheckoutTimes.start_time,
                        end_time: adminCompanyCheckoutTimes.end_time,
                      })
                    ) +
                      parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                  )}`
                  : s.corporation_checkin_time &&
                    s.corporation_checkout_time
                    ? `${addCommas(booking.hourly_wage)}/${addCommas(
                      remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: s.corporation_checkin_time,
                          end_time: s.corporation_checkout_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                    )}`
                    : `-.-/-.-` : `-.-/-.-`}
            </p>
          </div>
          <hr />
          <div className="tb-container">
            <p>-.-</p>
            <p>
              {/* {s.admin_approve_checkout &&
              adminEmployeeCheckoutTimes.start_time &&
              adminEmployeeCheckoutTimes.end_time
                ? `${addCommas(booking.hourly_wage)}/${addCommas(
                    remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: adminEmployeeCheckoutTimes.start_time,
                          end_time: adminEmployeeCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage))
                  )}`
                : `-.-/-.-`} */}
              {s.admin_approve_checkout ?
                adminEmployeeCheckoutTimes.start_time &&
                  adminEmployeeCheckoutTimes.end_time
                  ? `${addCommas(booking.hourly_wage)}/${addCommas(
                    remove_commas(booking.hourly_wage) *
                    (parseFloat(
                      workHours({
                        start_time: adminEmployeeCheckoutTimes.start_time,
                        end_time: adminEmployeeCheckoutTimes.end_time,
                      })
                    ) +
                      parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                  )}`
                  : s.corporation_checkin_time &&
                    s.corporation_checkout_time
                    ? `${addCommas(booking.hourly_wage)}/${addCommas(
                      remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: s.corporation_checkin_time,
                          end_time: s.corporation_checkout_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                    )}`
                    : `-.-/-.-` : `-.-/-.-`}
            </p>
          </div>
        </td>
        {/** Vacation total (Feriep)   **/}
        <td>
          <div className="tb-container">
            <p>-.-</p>
            <p>
              {/* {`${
              s.admin_approve_invoice &&
              adminCompanyCheckoutTimes.start_time &&
              adminCompanyCheckoutTimes.end_time
                ? `(${Math.abs(
                    0.125 * remove_commas(booking.hourly_wage)
                  ).toFixed(2)})`
                : `-.-`
            }`} */}
              {`${s.admin_approve_invoice ?
                  adminCompanyCheckoutTimes.start_time &&
                    adminCompanyCheckoutTimes.end_time
                    ? `(${Math.abs(
                      0.125 * remove_commas(booking.hourly_wage)
                    ).toFixed(2)})`
                    : s.corporation_checkin_time &&
                      s.corporation_checkout_time
                      ? `(${Math.abs(
                        0.125 * remove_commas(booking.hourly_wage)
                      ).toFixed(2)})`
                      : `-.-` : `-.-`
                }`}
            </p>
          </div>
          <hr />
          <div className="tb-container">
            <p>-.-</p>
            <p>
              {/* {`${
              s.admin_approve_checkout &&
              adminEmployeeCheckoutTimes.start_time &&
              adminEmployeeCheckoutTimes.end_time
                ? addCommas(
                    0.125 *
                      remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: adminEmployeeCheckoutTimes.start_time,
                          end_time: adminEmployeeCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage))
                  )
                : `-.-`
            }`} */}
              {`${s.admin_approve_checkout ?
                  adminEmployeeCheckoutTimes.start_time &&
                    adminEmployeeCheckoutTimes.end_time
                    ? addCommas(
                      0.125 *
                      remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: adminEmployeeCheckoutTimes.start_time,
                          end_time: adminEmployeeCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                    )
                    : s.corporation_checkin_time &&
                      s.corporation_checkout_time
                      ? addCommas(
                        0.125 *
                        remove_commas(booking.hourly_wage) *
                        (parseFloat(
                          workHours({
                            start_time: s.corporation_checkin_time,
                            end_time: s.corporation_checkout_time,
                          })
                        ) +
                          parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                      )
                      : `-.-` : `-.-`
                }`}
            </p>
          </div>
        </td>
        {/** TjenerTeamet fee (Tillæg) **/}
        <td>
          <div className="tb-container">
            <p>-</p>
            <p>
              {/* {`${
              s.admin_approve_invoice &&
              adminCompanyCheckoutTimes.start_time &&
              adminCompanyCheckoutTimes.end_time
                ? `(${addCommas(remove_commas(booking.fee))}) / ${addCommas(
                    remove_commas(booking.fee) *
                      (parseFloat(
                        workHours({
                          start_time: adminCompanyCheckoutTimes.start_time,
                          end_time: adminCompanyCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage))
                  )}`
                : `-.-`
            }`} */}
              {`${s.admin_approve_invoice ?
                  adminCompanyCheckoutTimes.start_time &&
                    adminCompanyCheckoutTimes.end_time
                    ? `(${addCommas(remove_commas(booking.fee))}) / ${addCommas(
                      remove_commas(booking.fee) *
                      (parseFloat(
                        workHours({
                          start_time: adminCompanyCheckoutTimes.start_time,
                          end_time: adminCompanyCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                    )}`
                    : s.corporation_checkin_time &&
                      s.corporation_checkout_time
                      ? `(${addCommas(remove_commas(booking.fee))}) / ${addCommas(
                        remove_commas(booking.fee) *
                        (parseFloat(
                          workHours({
                            start_time: s.corporation_checkin_time,
                            end_time: s.corporation_checkout_time,
                          })
                        ) +
                          parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                      )}`
                      : `-.-` : `-.-`
                }`}
            </p>
          </div>
          <hr />
          <div className="tb-container">
            <p>-.-</p>
            <p>-.-</p>
          </div>
        </td>
        {/** Total price included all (Total)  **/}
        <td>
          <div className="tb-container">
            <p>-</p>
            <p>
              {/* {s.admin_approve_invoice &&
              adminCompanyCheckoutTimes.start_time &&
              adminCompanyCheckoutTimes.end_time
                ? `(${Math.abs(
                    (1.125 *
                      remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: adminCompanyCheckoutTimes.start_time,
                          end_time: adminCompanyCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage)) +
                      parseFloat(booking.transport_wage) +
                      remove_commas(booking.fee) *
                        (parseFloat(
                          workHours({
                            start_time: adminCompanyCheckoutTimes.start_time,
                            end_time: adminCompanyCheckoutTimes.end_time,
                          })
                        ) +
                          parseFloat(booking.transport_wage)) +
                      parseFloat(booking.transport_wage)) /
                      (parseFloat(
                        workHours({
                          start_time: adminCompanyCheckoutTimes.start_time,
                          end_time: adminCompanyCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage))
                  ).toFixed(2)}) / ${addCommas(
                    (
                      1.125 *
                        remove_commas(booking.hourly_wage) *
                        (parseFloat(
                          workHours({
                            start_time: adminCompanyCheckoutTimes.start_time,
                            end_time: adminCompanyCheckoutTimes.end_time,
                          })
                        ) +
                          parseFloat(booking.transport_wage)) +
                      remove_commas(booking.fee) *
                        (parseFloat(
                          workHours({
                            start_time: adminCompanyCheckoutTimes.start_time,
                            end_time: adminCompanyCheckoutTimes.end_time,
                          })
                        ) +
                          parseFloat(booking.transport_wage))
                    ).toFixed(2)
                  )}`
                : "-"} */}
              {s.admin_approve_invoice ?
                adminCompanyCheckoutTimes.start_time &&
                  adminCompanyCheckoutTimes.end_time
                  ? `(${Math.abs(
                    (1.125 *
                      remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: adminCompanyCheckoutTimes.start_time,
                          end_time: adminCompanyCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage)) +
                      parseFloat(booking.transport_wage) +
                      remove_commas(booking.fee) *
                      (parseFloat(
                        workHours({
                          start_time: adminCompanyCheckoutTimes.start_time,
                          end_time: adminCompanyCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage)) +
                      parseFloat(booking.transport_wage)) /
                    (parseFloat(
                      workHours({
                        start_time: adminCompanyCheckoutTimes.start_time,
                        end_time: adminCompanyCheckoutTimes.end_time,
                      })
                    ) +
                      parseFloat(booking.transport_wage))
                  ).toFixed(2)}) / ${addCommas(
                    (
                      1.125 *
                      remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: adminCompanyCheckoutTimes.start_time,
                          end_time: adminCompanyCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage)) +
                      remove_commas(booking.fee) *
                      (parseFloat(
                        workHours({
                          start_time: adminCompanyCheckoutTimes.start_time,
                          end_time: adminCompanyCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage))
                    ).toFixed(2)
                  )}`
                  : s.corporation_checkin_time &&
                    s.corporation_checkout_time
                    ? `(${Math.abs(
                      (1.125 *
                        remove_commas(booking.hourly_wage) *
                        (parseFloat(
                          workHours({
                            start_time: s.corporation_checkin_time,
                            end_time: s.corporation_checkout_time,
                          })
                        ) +
                          parseFloat(booking.transport_wage)) +
                        parseFloat(booking.transport_wage) +
                        remove_commas(booking.fee) *
                        (parseFloat(
                          workHours({
                            start_time: s.corporation_checkin_time,
                            end_time: s.corporation_checkout_time,
                          })
                        ) +
                          parseFloat(booking.transport_wage)) +
                        parseFloat(booking.transport_wage)) /
                      (parseFloat(
                        workHours({
                          start_time: s.corporation_checkin_time,
                          end_time: s.corporation_checkout_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage))
                    ).toFixed(2)}) / ${addCommas(
                      (
                        1.125 *
                        remove_commas(booking.hourly_wage) *
                        (parseFloat(
                          workHours({
                            start_time: s.corporation_checkin_time,
                            end_time: s.corporation_checkout_time,
                          })
                        ) +
                          parseFloat(booking.transport_wage)) +
                        remove_commas(booking.fee) *
                        (parseFloat(
                          workHours({
                            start_time: s.corporation_checkin_time,
                            end_time: s.corporation_checkout_time,
                          })
                        ) +
                          parseFloat(booking.transport_wage))
                      ).toFixed(2)
                    )}`
                    : "-" : "-"}
            </p>
          </div>
          <hr />
          <div className="tb-container">
            <p>-</p>
            <p>
              {/* {s.admin_approve_checkout &&
              adminEmployeeCheckoutTimes.start_time &&
              adminEmployeeCheckoutTimes.end_time
                ? addCommas(
                    1.125 *
                      remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: adminEmployeeCheckoutTimes.start_time,
                          end_time: adminEmployeeCheckoutTimes.end_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage))
                  )
                : "-"} */}
              {s.admin_approve_checkout ?
                adminEmployeeCheckoutTimes.start_time &&
                  adminEmployeeCheckoutTimes.end_time
                  ? addCommas(
                    1.125 *
                    remove_commas(booking.hourly_wage) *
                    (parseFloat(
                      workHours({
                        start_time: adminEmployeeCheckoutTimes.start_time,
                        end_time: adminEmployeeCheckoutTimes.end_time,
                      })
                    ) +
                      parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                  )
                  : s.corporation_checkin_time &&
                    s.corporation_checkout_time
                    ? addCommas(
                      1.125 *
                      remove_commas(booking.hourly_wage) *
                      (parseFloat(
                        workHours({
                          start_time: s.corporation_checkin_time,
                          end_time: s.corporation_checkout_time,
                        })
                      ) +
                        parseFloat(booking.transport_wage)) - (booking.self_paid_break ? .5 : 0)
                    )
                    : "-" : "-"}
            </p>
          </div>
        </td>
        {/** Sent to Zenegy (Sendt)**/}
        <td style={{ position: "relative" }}>
          <hr style={{ position: "absolute", bottom: "47px" }} />
          <p
            style={{
              position: "absolute",
              bottom: "20px",
              left: "1px",
              padding: "0 0.25rem",
            }}
            className="label"
          >
            Zenegy
          </p>
          {s.is_approved_for_payroll && <CheckCircleIcon className="success" />}
        </td>
      </tr>
    </tbody>
  );
};

const mapDispatchToProps = (dispatch) => ({
  takeOverEmployee: bindActionCreators(takeOverEmployee, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(null, mapDispatchToProps)(BookingInvoiceShift);
