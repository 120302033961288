// Modules
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { css } from "emotion";

// Components
import MapMarkerIcon from "mdi-react/MapMarkerCircleIcon";
import WalkIcon from "mdi-react/WalkIcon";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import ClipboardTextOutlineIcon from "mdi-react/ClipboardTextOutlineIcon";
import DateViewer from "../ui/DateViewer";

// Utilities and config
import staff_type_converter from "../../utilities/staff_type_converter";
import { workHours } from "../../utilities/get-work-hours-total";
import { addStrings } from "../../utilities/add_string";

// Styles
import colors from "../../styles/colors";
import colorsNew from "../../styles/colors-V-1.0";
import bookingTypes from "../../config/bookingTypes";
import ViewDeprecatedBooking from "./viewDeprecatedBooking/ViewDeprecatedBooking";
import { bindActionCreators } from "redux";
import { showModal } from "../../actions/uiActions";
import { connect, useSelector } from "react-redux";
import { breakPoint } from "../../styles/breakPoints";
import req from "../../utilities/request-utility";
import Spinner from "../ui/Spinner";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { remove_commas } from "../../utilities/remove_commas";
import { addCommas } from "../../utilities/add_commas";
import getInvoiceTotalFee from "../../utilities/get-invoice-total-fee";
import getInvoiceTotalForCorporation from "../../utilities/get-invoice-total-for-corporation";
import {
  bookingFee
} from "../../utilities/calculate-booking-fee";

const Booking = (props) => {
  const history = useHistory();

  const { booking, selectedShifts, readOnly } = props;
  const [expanded, setExpanded] = useState(false);
  const [bookingFees, setBookingFees] = useState({});
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = () => {
    // if checkout approved by admin lock the booking
    if (booking.status === bookingTypes.deprecated) {
      props.showModal({
        content: <ViewDeprecatedBooking bookingId={booking.id} />
      });
    } else if (booking.status === bookingTypes.finished) {
      history.push(`/checkout-employees/${booking.id}`);
    } else {
      history.push("/viewBooking");
    }
  };
  const showMore = async(e) => {
    e.stopPropagation();
    setLoading(true)
    req()
    .put(`corporations/bookings/${booking.id}/change-booking-ui-status`, {box_status: !expanded})
    .then(() => {
      setExpanded((expanded) => !expanded)
      setLoading(false)  
    }) .catch(() => {
      setLoading(false);
    });
  }
  useEffect(()=>{
    if(booking.box_status){
      setExpanded(true);
    }
  }, [booking])
  useEffect(() => {
    bookingFeeFetch();
  }, [])
  const {standard_fee, urgentBookingFee, emergencyBookingFee} = bookingFees;
  const { takenOverCorporation, is_admin, user } = useSelector(state => state.auth.user);
  const bookingFeeFetch = async() => {
    let defaultFee = null;
    if(booking.status != 'open'){
      defaultFee = {standard_fee: booking?.standard_fee, urgent_booking_fee: booking?.urgent_booking_fee, emergency_booking_fee: booking?.emergency_booking_fee};
    }
    const {standard_fee, urgentBookingFee, emergencyBookingFee} = await bookingFee(booking.staff_type, defaultFee);
    setBookingFees({standard_fee, urgentBookingFee, emergencyBookingFee})
  }

    const totalShifts = selectedShifts?.length || 0;

    let checkoutLabels = {statusLabelText: '', statusLabelColor: '', employeeCheckedOutCount: 0, corporationCheckedOutCount: 0, ratingCount: 0, invoiceStatusText: '', invoiceStatusColor: '', admin_approve_count: 0};
    let checkoutRejectedCountEmployee = 0
    let checkoutRejectedCountCorporation = 0
    for(let s of selectedShifts || []){
      const employeeRatings = s.employee.employeeRatings || s.employeeRatings;
      if(employeeRatings?.some(rating => (rating.score > 0 && rating.booking_id === booking.id))){
        checkoutLabels = {...checkoutLabels, ratingCount: (checkoutLabels.ratingCount + 1)};
      }
      const employeeCheckedOut = s.employee_checkin_time && s.employee_checkout_time;
      const corporationCheckedOut = s.corporation_checkin_time && s.corporation_checkout_time;

      const checkoutRejected = employeeCheckedOut && corporationCheckedOut && (s.employee_checkin_time != s.corporation_checkin_time || s.employee_checkout_time != s.corporation_checkout_time);
      const checkoutRejectedByCorporation = checkoutRejected && s.last_checkout_by == "corporation";
      const checkoutRejectedByEmployee = checkoutRejected && s.last_checkout_by == "employee";
      if(corporationCheckedOut && !checkoutRejectedByEmployee){
        checkoutLabels = {...checkoutLabels, corporationCheckedOutCount: (checkoutLabels.corporationCheckedOutCount + 1) }
      }
      if(employeeCheckedOut && !checkoutRejectedByCorporation){
        checkoutLabels = {...checkoutLabels, employeeCheckedOutCount: (checkoutLabels.employeeCheckedOutCount + 1) }
      }
      if(s.admin_approve_invoice){
        checkoutLabels = {...checkoutLabels, admin_approve_count: (checkoutLabels.admin_approve_count + 1) }
      }
      if(checkoutRejected){
        checkoutLabels = {...checkoutLabels, checkoutRejectedCount: (checkoutLabels.checkoutRejectedCount + 1), statusLabelText: 'Afvist', statusLabelColor: colors.custardYellow }
        if(checkoutRejectedByEmployee){
          checkoutRejectedCountEmployee++
          checkoutLabels = {...checkoutLabels, statusLabelText: `Afvist ${checkoutRejectedCountEmployee}`  }
        }
        else if(checkoutRejectedByCorporation){
          checkoutRejectedCountCorporation++
          checkoutLabels = {...checkoutLabels, statusLabelText: `Afvist ${checkoutRejectedCountCorporation}` }
        }
      }
    }
    if(!checkoutLabels.statusLabelText){
      if(totalShifts == checkoutLabels.admin_approve_count){
        checkoutLabels = {...checkoutLabels, statusLabelText: 'Godkendt', statusLabelColor: colorsNew.green_50, invoiceStatusColor: colors.darkerGrey, invoiceStatusText: 'FAKTURA KLAR'};
      }
      else if(totalShifts == checkoutLabels.corporationCheckedOutCount){
        checkoutLabels = {...checkoutLabels, statusLabelText: 'Stemplet ud', statusLabelColor: colorsNew.green_50};
      }
      else{
        checkoutLabels = {...checkoutLabels, statusLabelText: 'Stempl ud', statusLabelColor: colorsNew.red_20};
      }
    }
    if(booking.all_check_times_approved){
      checkoutLabels = {...checkoutLabels, statusLabelText: 'Lukket', statusLabelColor: colorsNew.green_50, invoiceStatusColor: colorsNew.green_50, invoiceStatusText: 'FAKTURA SENDT'};
    }
    const {statusLabelColor, statusLabelText, employeeCheckedOutCount, corporationCheckedOutCount, ratingCount, invoiceStatusColor, invoiceStatusText} = checkoutLabels;
    let total_working_hours = 0;
    let shift_working_hours = 0;
    let urgent_booking_fee = 0;
    let emergency_booking_fee = 0;
    if(booking.fee > standard_fee){
      urgent_booking_fee = urgentBookingFee;
    }
    if(booking.fee > parseInt(standard_fee)+parseInt(urgentBookingFee)){
      emergency_booking_fee = emergencyBookingFee;
    }
    let grand_total = 0;
    let ferie = Math.abs(
      0.125 * remove_commas(booking.hourly_wage)
    ).toFixed(2)
    let total_transport = parseFloat(booking.hourly_wage)+parseFloat(ferie);
    let total_transport_time = parseFloat(booking.transport_wage) * totalShifts
    let grand_total_transport = total_transport * total_transport_time;

    total_working_hours += parseFloat(total_transport_time);
  
    let total_pr_time = addCommas((parseFloat(remove_commas(booking.hourly_wage)) + parseFloat(ferie) + parseFloat(standard_fee)+parseFloat(urgent_booking_fee)+parseFloat(emergency_booking_fee))?.toFixed(2))
  return (
    <div
      onClick={() => !readOnly && onSubmitHandler()}
      className={`${componentStyle(colors, readOnly, loading)} ${props.className || ""}`}
      style={props.style}
    >
      <div className="inside-component">
        {props.waitingApproval && (
          <div
            style={{ position: "absolute", top: "-28px", fontWeight: "bold" }}
          >
            <p>{booking.corporation.name}</p>
          </div>
        )}

        <div className="date-container">
          <DateViewer date={booking.date} className="date-style" size={23} showYear={true} />
        </div>

        <div className="content-box">
          <div className="default-content">
            <div className="container-a">
              <p className="container-a-title"><ClipboardTextOutlineIcon size={20} /><span>{booking.arrangement_type ? booking.arrangement_type : booking.arrangement_type_other }</span></p>
              <div className="container-a-b">
                <div className="time-container">
                <ClockOutlineIcon size={20} />
                  <p> {`${
                    booking.start_time
                  } - ${booking.end_time} [${workHours({
                    start_time: booking.start_time,
                    end_time: booking.end_time
                  })}/t]`}</p>
                </div>
                <p className="staff-type-container"><WalkIcon size={20} /> {booking.jobRole?.job_title.toUpperCase()}</p>
                <div className="address-container">
                  <MapMarkerIcon size={20} />
                  <p className="text-xs text-gray-600">
                    {`${booking.address}, ${booking.zip_code} ${booking.city}`}
                  </p>
                </div>
              </div>
            </div>

            <div className="container-b">
              <div className="container-a-b">

                    <div className="label-wrapper">
                      {invoiceStatusText && (<p className="label" style={{backgroundColor: invoiceStatusColor, width: 'auto'}}>{invoiceStatusText}</p>) }
                      <p className={`label ml-2`} style={{backgroundColor: statusLabelColor, color: statusLabelColor == "#ecc94b" ? colors.darkerGrey : '#fff'}}>{statusLabelText}</p>
                    </div>
                    <div className="label-wrapper">
                      <span>Stemplet ud</span>
                      <p className={`label ml-2`} style={{backgroundColor: totalShifts == corporationCheckedOutCount ? colorsNew.green_50 : colorsNew.red_20}}>{corporationCheckedOutCount} af {totalShifts}</p>
                    </div>
                    <div className="label-wrapper">
                      <span>Rating</span>
                      <p className={`label ml-2`} style={{backgroundColor: totalShifts == ratingCount ? colorsNew.green_50 : colors.custardYellow, color: totalShifts == ratingCount ? '#fff' : colors.darkerGrey}}>{ratingCount} af {totalShifts}</p>
                    </div>
                    <div className="label-wrapper">
                      <span>Personale Stemplet ud</span>
                      <p className={`label ml-2`} style={{backgroundColor: totalShifts == employeeCheckedOutCount ? colorsNew.green_50 : colors.darkerGrey}}>{employeeCheckedOutCount} af {totalShifts}</p>
                    </div>
              </div>
            </div>
          </div>
          {
            expanded && !readOnly && (
              <div className="expanded-content"  onClick={(e) => e.stopPropagation()}>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Antal</th>
                      <th>Pr. time</th>
                      <th>Ferie</th>
                      <th>Enhedspris</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    
                    selectedShifts?.map((shift, index) => {
                      const display_time = (shift.admin_checkin_time || shift.corporation_checkin_time || shift.employee_checkin_time);
                      let start_time = booking.start_time;
                      let end_time = booking.end_time;
                            if(shift.employee_checkin_time && shift.employee_checkout_time) {
                              start_time = shift.employee_checkin_time;
                              end_time = shift.employee_checkout_time
                            }
                            if(shift.corporation_checkin_time && shift.corporation_checkout_time) {
                              start_time = shift.corporation_checkin_time;
                              end_time = shift.corporation_checkout_time
                            }
                            if(shift.admin_checkin_time && shift.admin_checkout_time) {
                              start_time = shift.admin_checkin_time;
                              end_time = shift.admin_checkout_time
                            }
                            let working_hours = workHours({
                              start_time,
                              end_time
                            });
                            if(booking.self_paid_break){
                              working_hours = addCommas(addStrings(working_hours, "0.5", "subtract"))
                            }                                                      
                            let unit_total = parseFloat(booking.hourly_wage) + parseFloat(ferie);
                            let pr_time = parseFloat(booking.hourly_wage)?.toFixed(2)
                            let total = parseFloat(unit_total*working_hours.replace(",", "."));
                            if(display_time){
                              shift_working_hours = parseFloat(working_hours.replace(",", "."));
                              total_working_hours += parseFloat(working_hours.replace(",", "."))
                              grand_total += total;
                            }
                            const {corporation_checkin_time, employee_checkin_time, employee_checkout_time, corporation_checkout_time, last_checkout_by} = shift;
                            const employeeCheckedOut = employee_checkin_time && employee_checkout_time;
                            const corporationCheckedOut = corporation_checkin_time && corporation_checkout_time;
                      
                            const checkoutApproved =
                            employeeCheckedOut &&
                            corporationCheckedOut &&
                            corporation_checkin_time == employee_checkin_time &&
                            corporation_checkout_time == employee_checkout_time;
                            
                            const checkoutRejected =
                            employeeCheckedOut &&
                            corporationCheckedOut &&
                            (corporation_checkin_time != employee_checkin_time ||
                              corporation_checkout_time != employee_checkout_time);
                              const checkoutRejectedByCompany =
                              checkoutRejected && last_checkout_by == "corporation";
                            const checkoutApprovedByEmployee =
                              checkoutApproved && last_checkout_by == "employee";
                          
                            let companyColor =
                            checkoutRejected &&
                            !(checkoutRejectedByCompany && !shift.checkout_rejected_count_employee)
                              ? "#a83b3b"
                              : checkoutApproved
                              ? "#417505"
                              : "";  

                      return (
                        <tr>
                          <td className="left">{index+1}. <span style={{color: companyColor, fontWeight: companyColor ? 'bold' : 'normal'}}>{`${start_time} - ${end_time}`}</span> {shift.employee.first_name} { shift.employee.last_name?.substring(0, 1)?.toUpperCase()}  {booking.self_paid_break ? '(-30m.)' : ''}</td>
                          <td>{display_time ? addCommas(parseFloat(working_hours.replace(",", "."))?.toFixed(2)) : '-'}</td>
                          <td>{addCommas(pr_time)}</td>
                          <td>{addCommas(parseFloat(ferie)?.toFixed(2))}</td>
                          <td>{addCommas(parseFloat(unit_total)?.toFixed(2))}</td>
                          <td>{display_time ? addCommas(parseFloat(total)?.toFixed(2)) : '-'}</td>
                        </tr>
                      )
                    })
                  }
                  {
                    parseFloat(booking.transport_wage) > 0 ? (
                      <tr>
                        <td className="left pad-left">Transporttillæg</td>
                        <td>{addCommas(parseFloat(total_transport_time)?.toFixed(2))}</td>
                        <td>{addCommas(parseFloat(booking.hourly_wage)?.toFixed(2))}</td>
                        <td>{addCommas(parseFloat(ferie)?.toFixed(2))}</td>
                        <td>{addCommas(parseFloat(total_transport)?.toFixed(2))}</td>
                        <td>{addCommas(parseFloat(grand_total_transport)?.toFixed(2))}</td>
                      </tr>
                    ) : <></>
                  }
                      <tr>
                        <td colSpan={6}> &nbsp;</td>
                      </tr>
                      <tr>
                        <td className="left pad-left">Service tillæg</td>
                        <td>{parseFloat(total_working_hours) ? addCommas(parseFloat(total_working_hours)?.toFixed(2)) : '-'}</td>
                        <td>{addCommas(parseFloat(standard_fee)?.toFixed(2))}</td>
                        <td></td>
                        <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(standard_fee) * (parseFloat(shift_working_hours))))?.toFixed(2)) : '-'}</td>
                        <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(standard_fee) * (parseFloat(total_working_hours))))?.toFixed(2)) : '-'}</td>
                      </tr>
                      {
                        urgent_booking_fee ? (
                          <tr>
                          <td className="left pad-left">{`Hastebooking (<48 timer)`}</td>
                          <td>{parseFloat(total_working_hours) ? addCommas(parseFloat(total_working_hours)?.toFixed(2)) : '-'}</td>
                          <td>{addCommas(parseFloat(urgent_booking_fee)?.toFixed(2))}</td>
                          <td></td>
                          <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(urgent_booking_fee) * (parseFloat(shift_working_hours))))?.toFixed(2)) : '-'}</td>
                          <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(urgent_booking_fee) * (parseFloat(total_working_hours))))?.toFixed(2)) : '-'}</td>
                        </tr> 
                        ) : <></>
                      }
                      {
                        emergency_booking_fee ? (
                          <tr>
                            <td className="left pad-left">{`Akutbooking (<24 timer)`}</td>
                            <td>{parseFloat(total_working_hours) ? addCommas(parseFloat(total_working_hours)?.toFixed(2)) : '-'}</td>
                            <td>{addCommas(parseFloat(emergency_booking_fee)?.toFixed(2))}</td>
                            <td></td>
                            <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(emergency_booking_fee) * (parseFloat(shift_working_hours))))?.toFixed(2)) : '-'}</td>
                            <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(emergency_booking_fee) * (parseFloat(total_working_hours))))?.toFixed(2)) : '-'}</td>
                          </tr>  
                        ) : <></>
                      }
                      <tr>
                        <td colSpan={6}> &nbsp;</td>
                      </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th className="left">Total eks. moms DKK.</th>
                      <th>{parseFloat(total_working_hours) ? addCommas(parseFloat(total_working_hours)?.toFixed(2)) : '-'}</th>
                      <th>{total_pr_time}</th>
                      <th></th>
                      <th></th>
                      <th>{parseFloat(total_working_hours) ? addCommas(parseFloat(grand_total + ((parseFloat(standard_fee)+parseFloat(urgent_booking_fee)+parseFloat(emergency_booking_fee)) * total_working_hours))?.toFixed(2)) : '-'}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>  
            )
          }
          {
            !readOnly && (
              <div className="buttons-list" onClick={(e) => {e.stopPropagation();}}>
                <div className="show-more" onClick={showMore}>
                  {
                    !loading ? <button><DotsHorizontalIcon size={50}/></button> : <Spinner size={20} />
                  }
                </div>  
              </div>  
            )
          }

        </div>
      </div>
    </div>
  );
};

const componentStyle = (colors, readOnly, loading) => css`
  position: relative;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0rem;
  margin: ${readOnly? '1.25rem .75rem' : '1.25rem 0'};

  @media screen and (max-width: ${breakPoint.sm}px) {
    margin-top: 1rem;
  }

  &:hover {
    // background-color: ${colors.mediumGrey};
    background-color: transparent;
  }

  p.date-detail {
    font-size: 1.7rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 1rem;
    }
  }

  .label-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom:5px;
    span{
        margin-right:8px;
        display:inline-block;
        font-size: 13px;
        line-height: 18px;
        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 10px;
        }
        @media screen and (max-width: ${breakPoint.xs}px) {
          font-size: 7px;
        }
    }
    .on-label {
      margin-right: 0.25rem;
    }
  }

  .inside-component {
    display: flex;

    .date-container {
      display: flex;
      flex: 0 0 auto;
      max-width: 8%;
      justify-content: center;
      align-items: center;
      margin: 0 0.5rem;

      .date-style {
        .day,
        .month {
            font-size: 20px;
            line-height: 25px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 12px;
          }
        }

        .year {
          left: 20px;
          bottom: 4px;
          position:initial;  
          font-size: 20px;
          line-height: 25px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            bottom: 6px;
            font-size: 12px;
          }
        }

        .date {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 15px;
          }
        }
      }
    }

    .content-box {
      max-width: 92%;
      width: 100%;
      padding: 1rem;
      border: 1px solid ${colors.grey};
      background-color: ${colors.white};
      border-radius:5px;
      .mdi-icon {
        color: #4A4A4A;
      }
      .container-a {
          overflow: hidden;
          max-width: 55%;
        .container-a-title {
          display: -webkit-box;
          -webkit-box-align: center;
          align-items: center;
          font-size: 1rem;
          margin-bottom: 0.25rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          span{
            width: 95%;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          flex: 0 0 auto;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.55rem;
          }
        }

        .container-a-b {
          .time-container {
            display: flex;
            align-items: center;
            margin-bottom: 0.25rem;

            p {
              font-size: 1rem;
              margin-right: 0.5rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                font-size: 0.55rem;
              }
            }
          }

          .wage-container {
            margin-bottom: 0.25rem;

            p {
              font-size: 0.75rem;
              color: ${colors.darkerGrey};
            }
          }

          .address-container, .staff-type-container {
            display: flex;
            align-items: center;

            p {
              font-size: 0.65rem;
              color: ${colors.darkerGrey};

              @media screen and (max-width: ${breakPoint.sm}px) {
                font-size: 0.55rem;
              }
              @media screen and (max-width: ${breakPoint.xs}px) {
                font-size: 7px;
              }
            }

            svg {
              height: 1.25rem;
              width: 1.25rem;
              margin-right: 0.12rem;
            }

            @media screen and (max-width: ${breakPoint.sm}px) {
              svg {
                height: 0.85rem;
                width: 0.85rem;
              }
            }
          }
        }
      }
      .default-content{
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;  
        align-items: flex-start;
      }
      .expanded-content{
        z-index:1;
        position: relative;
        font-size: 12px;
        @media screen and (max-width: ${breakPoint.xs}px) {
          font-size: 7px;
        }
        padding: 10px 0;
        color: #4a4a4a;
        table{
          width: 100%;
          tbody {
            text-align: center;
          }
          .left{
            text-align: left;
          }
          .pad-left {
            padding-left: 13px;
          }
          .greenColor{
            color : ${colors.green_50};
            font-weight: bold;
          }
          .redColor {
            color : ${colors.red_20}
            font-weight: bold;
          }
        }
      }      
      .buttons-list {
        position: absolute;
        bottom: ${loading? '-12px' : '-31px'};
        display: flex;
        align-items: center;
        width: 87%;
        z-index:0;

        .box-group{
          display: flex;
          gap: 10px;
          left: 65%;
          position: absolute;

        }
        .box1{
          left: 5%;
          position: absolute;
        }
        .box {
          cursor: pointer;
          border-radius: 0.25rem;
          background-color: ${colors.white};
      
          p {
            padding: 0.25rem 1rem;
            font-size: 0.75rem;
            font-weight: bold;
            border: 1px solid ${colors.darkerGrey};
            border-radius: 3px;
            color: ${colors.grey_50};
            @media screen and (max-width: ${breakPoint.sm}px) {
              padding: 0.25rem;
              font-size: 0.55rem;
            }          
          }
        }
      
        .show-more{
          color: #fff;
          min-width: 100px;
          text-align: center;
          position: relative;
          left: calc(50% - 50px);
          button{
            outline: none;
          }
        }
  
      }

      .container-b {
        display: flex;
        align-items: center;

        @media screen and (max-width: ${breakPoint.sm}px) {
          padding-top: 0;
        }

        .container-a-b {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          .booked-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.55rem;
            }

            .staffs {
              margin-bottom: 0.25rem;
              text-align: end;
            }

            .selected-staff-container {
              display: flex;
              justify-content: space-between;
              width: 7rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                width: 4rem;
              }

              p {
                font-size: 0.875rem;

                @media screen and (max-width: ${breakPoint.sm}px) {
                  font-size: 0.55rem;
                }
              }
            }

            .applied-staff-container {
              display: flex;
              justify-content: space-between;
              width: 7rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                width: 4rem;
              }

              p {
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
                color: ${colors.darkGrey};

                @media screen and (max-width: ${breakPoint.sm}px) {
                  font-size: 0.55rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .countdown-container {
    margin-top: 0.25rem;

    p {
      background-color: ${colors.darkGrey};
      color: ${colors.white};
      border-radius: 0.25rem;
      text-align: center;
      font-size: 0.65rem;
      font-weight: 700;
      padding: 0.25rem 0.5rem;
    }
  }

  .label-container,
  .light-grey-label,
  .grey-label,
  .green-label,
  .yellow-label,
  .red-label,
  .label {
    text-align: center;
    padding: 0.25rem;
    min-width: 4rem;
    border-radius: 0.25rem;
    align-items: center;
    color: ${colors.white};
    font-weight: 600;
    font-size: 0.65rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.5rem;
      width: 4rem;
      min-width: 0;
    }
    @media screen and (max-width: ${breakPoint.xs}px) {
      font-size: 7px;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(showModal, dispatch)
});

export default connect(null, mapDispatchToProps)(Booking);
