// Libs
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { css } from "emotion";

// Utilities
import req from "../../../utilities/request-utility";

// Components
import MiddleSection from "../newBooking/MiddleSection";
import BottomSection from "../newBooking/BottomSection";
import TopSection from "../newBooking/TopSection";
import PriceSection from "../newBooking/PriceSection";
import PageContainer from "../../ui/PageContainer";
import NavigateBackArrow from "../../ui/NavigateBackArrow";
import Spinner from "../../ui/Spinner";

// Validation
import FormValidator from "../../../validation/formValidator";
import { bookingValidator } from "../../../validation/bookingValidator";

// Actions
import { addToast } from "../../../actions/uiActions";

const EditBooking = props => {
  const history = useHistory();

  // Params
  const { bookingId } = props.match.params;

  // Validation form
  const validator = new FormValidator(bookingValidator);

  const { user, takenOverCorporation } = useSelector(state => state.auth);

  const [booking, setBooking] = useState({
    id: user.id || null,
    corporation_id: 1,
    staff_type: "waiter",
    number_of_staff: 1,
    date: "",
    start_time: "10:00",
    end_time: "18:00",
    booking_person: "",
    address: "",
    booking_person_nr: "",
    zip_code: "",
    city: "",
    arrangement_type: "Firma event",
    arrangement_type_other: "",
    upper_dress_code: "Sort skjorte",
    upper_dress_code_other: "",
    lower_dress_code: "Sorte buks",
    lower_dress_code_other: "",
    shoes_dress_code: "Sorte sko",
    shoes_dress_code_other: "",
    item_to_bring: "",
    extra_work_hours: "0",
    contact_person: "",
    food_included: 0,
    job_description: "",
    access_information: "",
    transport_wage: "0",
    hourly_wage: "192",
    fee: 0,
    validation: validator.valid(),
    language: "",
    invoice_note: "",
    address_url: "",
    admin_message: "",
    self_paid_break: false,
  });
  const [loading, setLoading] = useState(true);

  // Validation submitted ( Validation)
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(booking.validation);

  useEffect(() => {
    req()(`corporations/bookings/${bookingId}/editable`)
      .then(({ data: booking }) => {
        booking.food_included = booking.food_included ? 1 : 0;
        booking.validation = validator.valid();
        setBooking(booking);
        setLoading(false);
      })
      .catch(err => {
        props.addToast({
          message: "Kunne ikke hente oplysningerne..",
          type: "error"
        });
      });
    // eslint-disable-next-line
  }, []);

  const updateBooking = async () => {
    const validation =  await validator.validate(booking);
    const tempBooking = { ...booking };
    tempBooking.validation = validation;
    setSubmitted(true);
    if (validation.isValid) {
      setLoading(true);
      await req().put(`corporations/bookings/${bookingId}`, booking);

      if (props.location.search) {
        history.push(`/admin/approve-booking-overview`);
      } else {
        history.push(`/viewBooking/${bookingId}`);
      }

      props.addToast({
        message: "Opdateret booking",
        type: "success",
        autoDismiss: true
      });
    } else {
      // Force rerender component
      setBooking(tempBooking);
      // Show warning
      props.addToast({
        message: "Nogle felter er ikke udfyldt korrekt",
        type: "warning",
        autoDismiss: true
      });
    }
  };

  const setPriceAndState = e => {
    let tempBooking = { ...booking };
    tempBooking[e.target.name] = e.target.value;
    setBooking(tempBooking);
  };

  // Validation
  useEffect(() => {
    const updateValidation = async () => {
      if (submitted) {
        const result = await validator.validate(booking);
        setValidation(result);
      } else {
        setValidation(booking.validation);
      }
    };
  
    updateValidation();
    // eslint-disable-next-line
  }, [booking, submitted]);
  
  return (
    <PageContainer showNavigation={true}>
      <NavigateBackArrow navigateURL={() => history.goBack()} />

      {loading && <Spinner title={"Henter booking information..."} />}

      {!loading && (
        <div className={componentStyle()}>
          <TopSection
            edit={user.is_admin || takenOverCorporation ? false : true}
            booking={booking}
            setBooking={setBooking}
            validation={validation}
          />

          <MiddleSection
            booking={booking}
            setBooking={setBooking}
            validation={validation}
            setPriceAndState={setPriceAndState}
          />

          <PriceSection
            edit={true}
            isSignedIn={user.id}
            booking={booking}
            setBooking={setBooking}
            validation={validation}
            action={updateBooking}
            buttonLabel={"Opdater booking"}
          />
          <BottomSection
            booking={booking}
            setBooking={setBooking}
          />

        </div>
      )}
    </PageContainer>
  );
};

const componentStyle = () => css`
  padding-left: 2rem;
  padding-right: 2rem;
`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(EditBooking);
